import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import {
  BarcodeCapture,
  barcodeCaptureLoader,
  BarcodeCaptureOverlay,
  BarcodeCaptureSettings,
  Symbology,
} from 'scandit-web-datacapture-barcode'
import {
  Camera,
  CameraSwitchControl,
  configure,
  DataCaptureContext,
  DataCaptureView,
  FrameSourceState,
  LaserlineViewfinder,
} from 'scandit-web-datacapture-core'
import PropTypes from 'prop-types'

export function createSDKFacade() {
  let context
  let view
  let laserLineViewFinder
  let settings
  let barcodeCapture
  let overlay
  let host
  let cameraSwitchControl
  let camera

  function createHostElementIfNeeded() {
    if (!host) {
      host = document.createElement('div')
      host.style.display = 'none'
      host.style.width = '100%'
      host.style.height = '100%'
      document.body.append(host)
    }
    return host
  }

  return {
    async initialize() {
      await configure({
        // libraryLocation: new URL('library/engine', document.baseURI).toString(),
        libraryLocation: '/engine',
        licenseKey:
          'AfwU0tyBSM+HEAeHvkHqEhYllu015lWjOzA3+AcskiiHYpaojm5RZFlwN/Bycvf8ak/Lmllum8l/BwyoehSDHOYMDOZ/b+IxPlYM34twRIVsU9xBQDa7PVBh8i34d9cbLh8OvO0NTaamM7U5rS2N+q6MG9ZM2urkFLbVX87dTi2wbRAEgpWJdUudXFP/dphtEfT0dfY8Fy68HAlNnlpHZPonCGqaVB27WtrXln35mlb0vdm57Yk6FdiXFA+xlWHpWZPH61db4Ugd70flPdKM03U6YOrE/lUXS/jtF4v9D0TV+Pbww5ucQAzdQ7cdat5QzEh6xiD0Ii/f3ryUlsj0zpAcbCXtRtqa19EHujnF6TPnwA9z8vXlICylTD30lo7syM15etIIV6E62nimqsHFQIJH5iu0KYlWdRXf9GyQ8ABAdDVvgSCzdg3cJ94ih6a9q/sLDHGcait7fm0CB6nuvaauzujIkVBnj+Fslos9q7tq4p3PJYtm3LeAoj/m4kn3UZpQ3QbPqgn4tNSv991u2N0pFqHxtLTANG6904mSw58JjOFOIgEQuB5VIjFU3cLNhus2dBEZm8e6VcCMmpVZ4hjKsHSYpUfkU2S8uQfGeSmU7Ag19v/UzJ0DMFmFOqsiB99QvcJFOYIHr/k3PicdFJtvowxXwXeSBbcBptWNpvS9SQuxRgfUyv1S9K5MyTdT2LnhqygO4wc0L/w+jZSD+8kli6NPOcOO0wjSUKI3Wfi/yk1Nyym9Zh3ruToZf/EPtg80eDsUcuICajHJ0nxe4uqdOKT5mSxsE5vAIoZ8efC6L2Gp3azUtzO87nM0fz1C',
        moduleLoaders: [barcodeCaptureLoader()],
      })
      context = await DataCaptureContext.create()
      settings = new BarcodeCaptureSettings()
      settings.enableSymbologies([
        Symbology.EAN13UPCA,
        Symbology.EAN8,
        Symbology.UPCE,
        Symbology.QR,
        Symbology.DataMatrix,
        Symbology.Code39,
        Symbology.Code128,
        Symbology.InterleavedTwoOfFive,
      ])

      view = await DataCaptureView.forContext(context)
      view.connectToElement(createHostElementIfNeeded())

      cameraSwitchControl = new CameraSwitchControl()
      view.addControl(cameraSwitchControl)

      barcodeCapture = await BarcodeCapture.forContext(context, settings)
      await barcodeCapture.setEnabled(false)

      overlay = await BarcodeCaptureOverlay.withBarcodeCaptureForView(
        barcodeCapture,
        view,
      )
      laserLineViewFinder = new LaserlineViewfinder()
      await overlay.setViewfinder(laserLineViewFinder)
      await view.addOverlay(overlay)

      camera = Camera.default
      await camera.applySettings(BarcodeCapture.recommendedCameraSettings)
      await context.setFrameSource(camera)
    },
    async cleanup() {
      await context?.frameSource?.switchToDesiredState(FrameSourceState.Off)
      await context?.dispose()
      await context?.removeAllModes()
      if (overlay) {
        await overlay.setViewfinder(null)
        await view?.removeOverlay(overlay)
      }
      if (cameraSwitchControl) {
        view?.removeControl(cameraSwitchControl)
        cameraSwitchControl = undefined
      }
      view?.detachFromElement()
      laserLineViewFinder = undefined
      barcodeCapture = undefined
      context = undefined
      view = undefined
      settings = undefined
      camera = undefined
      host?.remove()
      host = undefined
    },
    connectToElement(element) {
      host = createHostElementIfNeeded()
      host.style.display = 'block'
      element.append(host)
    },
    detachFromElement() {
      if (host) {
        host.style.display = 'none'
        document.body.append(host)
      }
    },
    async enableCamera(enabled) {
      if (context?.frameSource) {
        await context.frameSource.switchToDesiredState(
          enabled ? FrameSourceState.On : FrameSourceState.Off,
        )
      }
    },
    async enableScanning(enabled) {
      await barcodeCapture?.setEnabled(enabled)
    },
    async enableSymbology(symbology, enabled) {
      settings.enableSymbology(symbology, enabled)
      await barcodeCapture?.applySettings(settings)
    },
    addBarcodeCaptureListener(listener) {
      barcodeCapture?.addListener(listener)
    },
    removeBarcodeCaptureListener(listener) {
      barcodeCapture?.removeListener(listener)
    },
    getEnabledSymbologies() {
      return settings.enabledSymbologies
    },
  }
}

export const SDKContext = createContext({
  loaded: false,
  loading: false,
  sdk: null,
})

export default function SDKProvider({ children }) {
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const sdk = useMemo(() => createSDKFacade(), [])

  const providerValue = useMemo(
    () => ({ loading, loaded, sdk }),
    [loading, loaded, sdk],
  )

  useEffect(() => {
    async function start() {
      setLoading(true)
      await sdk.initialize()
      setLoading(false)
      setLoaded(true)
      // enable the camera on mount to speed up the access
      await sdk.enableCamera(true)
    }
    void start()
    return () => {
      void sdk.cleanup()
    }
  }, [sdk])

  return (
    <SDKContext.Provider value={providerValue}>{children}</SDKContext.Provider>
  )
}

SDKProvider.propTypes = {
  children: PropTypes.any,
}

export function useSDK() {
  const value = useContext(SDKContext)

  if (value.sdk === null) {
    throw new Error(
      'Sdk facade is null. Did you forget to wrap the component with SDKProvider?',
    )
  }
  return value
}
